@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  overflow-y: auto;
}

#main {
  height: 100vh;
  width: 100vw;
}
